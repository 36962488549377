<template>
  <FormulateForm
    ref="formClientTab"
    name="formClientTab"
    style="height: 100%"
  >
    <b-container class="tab-container">
      <b-card-actions
        no-actions
        :show-loading="fetching"
      >
        <b-row
          v-if="!(isOrder || hasPalmBeerCustomer)"
          align-h="end"
        >
          <b-col md="2">
            <e-button
              id="pdv-tab-client-btn-clean"
              variant="danger"
              text="Limpar"
              size="sm"
              @click="onCleanForm"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="5">
            <FormulateInput
              id="pdv-tab-client-person-type"
              :value="stClientForm.personType"
              name="personType"
              type="label"
              :options="personTypesOptions()"
              :label="$t('Tipo')"
            />
          </b-col>
          <b-col md="7">
            <FormulateInput
              v-if="!existClient"
              id="pdv-tab-client-document"
              v-model="stClientForm.document"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              name="document"
              type="text-mask"
              :label="$t('CPF ou CNPJ')"
              :placeholder="$t('CPF ou CNPJ')"
              validation="optional|cpfCnpj"
              :disabled="isOrder || hasPalmBeerCustomer"
              @input="onInputCustomerDocument"
            />
            <FormulateInput
              v-else
              id="pdv-tab-client-document"
              v-model="stClientForm.document"
              name="document"
              type="label"
              :label="$t('CPF ou CNPJ')"
              filter="cpfCnpj"
            />
          </b-col>
        </b-row>

        <transition name="fade">
          <div v-if="!isAnonymous">
            <template v-if="isNatural">
              <b-row>
                <b-col :md="!existClient ? '12' : isUpdating.name ? '8' : '4'">
                  <FormulateInput
                    id="pdv-tab-client-name"
                    ref="name"
                    v-model="stClientForm.name"
                    name="name"
                    :type="existClient && !isUpdating.name ? 'label' : 'text'"
                    :label="$t('Nome')"
                    :placeholder="$t('Nome')"
                  />
                </b-col>
                <b-col
                  v-if="enableEdit('name')"
                  style="position: relative"
                >
                  <e-button
                    v-if="existClient && !isUpdating.name && stClientForm.id != 1"
                    pill
                    class="edit-button"
                    variant="outline-secondary"
                    type="button"
                    icon="pencil"
                    @click="ableToUpdate('name')"
                  />
                  <b-col
                    v-if="isUpdating.name && stClientForm.id"
                    class="actions pf-actions"
                  >
                    <e-button
                      title="Confirmar"
                      size="sm"
                      variant="primary"
                      icon="check-circle"
                      style="margin-right: 6px"
                      @click="updateCompanyNameOrName(stClientForm.name, 'name')"
                    />
                    <e-button
                      title="Cancelar"
                      size="sm"
                      variant="danger"
                      icon="x-circle"
                      @click="cancelUpdate('name')"
                    />
                  </b-col>
                </b-col>
              </b-row>
            </template>

            <template v-if="isJuridical">
              <b-row align-v="center">
                <b-col :md="!existClient ? '12' : isUpdating.companyName ? '8' : '4'">
                  <FormulateInput
                    id="pdv-tab-client-company-name"
                    ref="companyName"
                    v-model="stClientForm.companyName"
                    name="corporateName"
                    :type="existClient && !isUpdating.companyName ? 'label' : 'text'"
                    :label="$t('Razão social')"
                    :placeholder="$t('Razão Social')"
                  />
                </b-col>
                <b-col
                  v-if="enableEdit('companyName')"
                  style="position: relative"
                >
                  <e-button
                    v-if="existClient && !isUpdating.companyName && stClientForm.id != 1"
                    pill
                    class="edit-button edit-button--pj"
                    variant="outline-secondary"
                    type="button"
                    icon="pencil"
                    @click="ableToUpdate('companyName')"
                  />
                  <div
                    v-if="isUpdating.companyName && stClientForm.id"
                    class="actions"
                  >
                    <e-button
                      title="Confirmar"
                      size="sm"
                      variant="primary"
                      icon="check-circle"
                      style="margin-right: 6px"
                      @click="updateCompanyNameOrName(stClientForm.companyName, 'companyName')"
                    />
                    <e-button
                      title="Cancelar"
                      size="sm"
                      variant="danger"
                      icon="x-circle"
                      @click="cancelUpdate('companyName')"
                    />
                  </div>
                </b-col>
              </b-row>
            </template>

            <b-row>
              <b-col>
                <FormulateInput
                  id="pdv-tab-client-email"
                  v-model="stClientForm.email"
                  name="email"
                  :type="existClient ? 'label' : 'text'"
                  validation="optional|email"
                  :filter="existClient ? 'emailMask' : null"
                  :label="$t('E-mail')"
                  :placeholder="$t('exemplo@email.com')"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <FormulateInput
                  v-if="!existClient"
                  id="pdv-tab-client-phone-landline"
                  v-model="stClientForm.landLine"
                  v-mask="'(##) ####-####'"
                  :placeholder="$t('(99) 9999-9999')"
                  name="phone"
                  type="text"
                  :label="$t('Telefone')"
                />
                <FormulateInput
                  v-else
                  id="pdv-tab-client-phone-landline"
                  :value="stClientForm.landLine"
                  name="phone"
                  type="label"
                  :label="$t('Telefone')"
                  filter="phone"
                />
              </b-col>
              <b-col>
                <FormulateInput
                  v-if="!existClient"
                  id="pdv-tab-client-phone-mobile"
                  v-model="stClientForm.mobilePhone"
                  v-mask="'(##) #####-####'"
                  :placeholder="$t('(99) 99999-9999')"
                  name="cellphone"
                  :type="existClient ? 'label' : 'text'"
                  :filter="existClient ? 'phone' : null"
                  :label="$t('Celular')"
                />
                <FormulateInput
                  v-else
                  id="pdv-tab-client-phone-mobile"
                  :value="stClientForm.mobilePhone"
                  name="cellphone"
                  type="label"
                  filter="phone"
                  :label="$t('Celular')"
                />
              </b-col>
            </b-row>
          </div>
        </transition>
      </b-card-actions>

      <transition name="fade">
        <div v-if="!isAnonymous">
          <b-card-actions
            :title="$t('Outros dados')"
            no-actions
            :show-loading="fetching"
          >
            <b-row>
              <b-col>
                <FormulateInput
                  id="pdv-tab-client-observation"
                  v-model="stClientForm.observation"
                  name="observation"
                  :type="existClient ? 'label' : 'textarea'"
                  :label="$t('Observação')"
                  :placeholder="$t('Observação')"
                />
              </b-col>
            </b-row>

            <!-- variante entre fisica e juridica -->
            <template v-if="isNatural">
              <b-row>
                <b-col>
                  <FormulateInput
                    id="pdv-tab-client-birthdate"
                    v-model="stClientForm.birthdate"
                    name="birthdate"
                    :type="existClient ? 'label' : 'datepicker'"
                    :filter="existClient ? 'dateMask' : null"
                    :label="$t('Data de nascimento')"
                    :disabled="existClient"
                    :max="today"
                  />
                </b-col>
              </b-row>
            </template>

            <template v-if="isJuridical">
              <b-row align-v="center">
                <b-col :md="!existClient ? '12' : isUpdating.name ? '8' : '4'">
                  <FormulateInput
                    id="pdv-tab-client-name"
                    ref="name"
                    v-model="stClientForm.name"
                    name="name"
                    :type="existClient && !isUpdating.name ? 'label' : 'text'"
                    :label="$t('Nome Fantasia')"
                    :placeholder="$t('Nome Fantasia')"
                  />
                </b-col>
                <b-col
                  v-if="enableEdit('name')"
                  style="position: relative"
                >
                  <e-button
                    v-if="existClient && !isUpdating.name"
                    pill
                    class="edit-button edit-button--pj"
                    variant="outline-secondary"
                    type="button"
                    icon="pencil"
                    @click="ableToUpdate('name')"
                  />
                  <div
                    v-if="isUpdating.name && stClientForm.id"
                    class="actions"
                  >
                    <e-button
                      title="Confirmar"
                      size="sm"
                      variant="primary"
                      icon="check-circle"
                      style="margin-right: 6px"
                      @click="updateCompanyNameOrName(stClientForm.name, 'name')"
                    />
                    <e-button
                      title="celar"
                      size="sm"
                      variant="danger"
                      icon="x-circle"
                      @click="cancelUpdate('name')"
                    />
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <FormulateInput
                    id="pdv-tab-client-state-registration-type"
                    v-model="stClientForm.stateRegistrationType"
                    name="stateRegistrationType"
                    :type="existClient ? 'label' : 'vue-select'"
                    :label="$t('Indicador da IE')"
                    :placeholder="$t('Selecione')"
                    :options="indicatorIETypes()"
                  />
                </b-col>
                <b-col>
                  <FormulateInput
                    id="pdv-tab-client-state-registration"
                    v-model="stClientForm.stateRegistration"
                    name="stateRegistration"
                    :type="existClient ? 'label' : 'text'"
                    :filter="existClient ? 'rgIeMask' : null"
                    :label="$t('Inscrição estadual')"
                    maxlength="20"
                    :disabled="[
                      indicatorIETypesEnum.EXEMPT_REGISTRATION,
                      indicatorIETypesEnum.NO_TAXPAYER,
                    ].includes(stClientForm.stateRegistrationType)
                    "
                  />
                </b-col>
              </b-row>
            </template>
          </b-card-actions>

          <b-row>
            <b-col>
              <template v-if="existClient">
                <b-card-actions
                  :title="$t('Endereço')"
                  :show-loading="fetching"
                  no-actions
                >
                  <e-address
                    v-model="stClientForm.address"
                    :required="false"
                    :show-loading="true"
                    :read-only="true"
                  />
                </b-card-actions>
              </template>
              <template v-else>
                <e-address
                  v-model="stClientForm.address"
                  :required="false"
                  :show-loading="fetching"
                />
              </template>
            </b-col>
          </b-row>
        </div>
      </transition>
    </b-container>
  </FormulateForm>
</template>

<script>
import _ from 'lodash'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import EAddress from '@/views/components/EAddress.vue'
import { indicatorIETypes, personTypes, formulateHelper } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import EButton from '@/views/components/EButton.vue'
import { validateBr } from 'js-brasil'
import axiosInstance from '@/libs/axios'

export default {
  components: { BContainer, BRow, BCol, EAddress, BCardActions, EButton },

  mixins: [indicatorIETypes, personTypes, formulateHelper],

  data() {
    return {
      fetching: false,
      isUpdating: {
        companyName: false,
        name: false,
      },
      stateBeforeUpdate: {
        companyName: null,
        name: null,
      },
    }
  },

  computed: {
    ...mapState('pages/pdv/payBox', {
      stClientForm: 'clientForm',
    }),
    ...mapGetters('pages/pdv/payBox', [
      'isOrder',
      'hasPalmBeerCustomer',
      'hasNewPalmBeerOperation',
    ]),

    today() {
      return new Date()
    },

    existClient() {
      return !!this.stClientForm.id
    },

    isNatural() {
      return this.stClientForm.personType === this.personTypeEnum.INDIVIDUAL
    },
    isJuridical() {
      return this.stClientForm.personType === this.personTypeEnum.JURIDICAL
    },
    isAnonymous() {
      return this.stClientForm.personType === this.personTypeEnum.CONSUMER
    },
  },

  methods: {
    ...mapActions('pages/pdv/payBox', {
      stSetClientForm: 'setClientForm',
      stCleanClientForm: 'cleanClientForm',
      stFetchClientByDocument: 'fetchClientByDocument',
      stcleanClientFormWithoutDocument: 'cleanClientFormWithoutDocument',
    }),

    enableEdit(companyNameOrName) {
      if (this.isUpdating[companyNameOrName]) return true

      if (this.stClientForm[companyNameOrName]) {
        const wordsList = this.stClientForm[companyNameOrName].split(' ')
        if (wordsList.length < 2) return true
      }

      const STATUS_TO_ABLE_TO_PATCH = [null, ' ', 'NÃO INFORMADO', '', undefined, ' - ']
      if (STATUS_TO_ABLE_TO_PATCH.includes(this.stClientForm[companyNameOrName])) return true

      return false
    },

    ableToUpdate(field) {
      const { context } = this.$refs[field]

      this.stateBeforeUpdate[field] = context.model
      this.changeInputType(field)
    },

    cancelUpdate(field) {
      this.changeInputType(field)
      this.$refs[field].context.model = this.stateBeforeUpdate[field]
    },

    changeInputType(field) {
      if (field === 'name') {
        this.isUpdating.name = !this.isUpdating.name
      } else {
        this.isUpdating.companyName = !this.isUpdating.companyName
      }
    },

    async updateCompanyNameOrName(textToUpdate, companyNameOrName) {
      const validationError = { message: '' }

      const hasOnlyWhiteSpaces = /^\s+$/.test(textToUpdate)
      if (hasOnlyWhiteSpaces) {
        validationError.message = this.$t('PAY_BOX_SALE.CUSTOMER.ONLY_WHITE_SPACES')
        return this.showError({ message: validationError.message })
      }

      const payload = {
        id: this.stClientForm.id,
      }

      if (companyNameOrName === 'companyName') {
        payload.companyName = textToUpdate.trim()
      } else {
        payload.name = textToUpdate.trim()
      }

      try {
        await axiosInstance.patch('/api/sales/pay-box-customer/update-name', payload)
      } catch (error) {
        this.showError({ error })
        return null
      }

      this.changeInputType(companyNameOrName)
      this.onInputCustomerDocument()

      this.showSuccess({ message: this.$t('Campo atualizado com sucesso') })
      return null
    },

    onFocusDocument() {
      this.focusInput('#pdv-tab-client-document')
    },

    // eslint-disable-next-line func-names
    onInputCustomerDocument: _.debounce(async function (text) {
      if (!this.$refs.formClientTab) return
      if (this.$refs.formClientTab.isValid) {
        await this.changePersonType(text)
        await this.onSearchCustomer()
      }
    }, 500),

    async onSearchCustomer() {
      if (this.isOrder) return
      const { document } = this.stClientForm

      if (!validateBr.cpfcnpj(document || '')) {
        this.stcleanClientFormWithoutDocument()
        return
      }

      const cleanedText = document.replace(/[./-]/g, '')

      try {
        this.fetching = true
        await this.stFetchClientByDocument(cleanedText)
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    async changePersonType(text) {
      const { CONSUMER, INDIVIDUAL, JURIDICAL } = this.personTypeEnum
      const data = { personType: this.stClientForm.personType }
      const cleanedText = text?.replace(/[./-]/g, '') || ''

      if (cleanedText.length < 11) {
        data.personType = CONSUMER
        await this.stSetClientForm(data)
        return
      }

      if (cleanedText.length === 11) {
        data.personType = INDIVIDUAL
      } else {
        data.personType = JURIDICAL
      }
      await this.stSetClientForm(data)
    },

    onCleanForm() {
      this.$formulate.resetValidation('formClientTab')
      this.stCleanClientForm()
      this.isUpdating.companyName = false
      this.isUpdating.name = false
    },

    validate() {
      this.$refs.formClientTab.showErrors()
      if (this.hasNewPalmBeerOperation && !this.existClient) {
        return false
      }

      return this.$refs.formClientTab.isValid
    },
  },
}
</script>

<style scoped>
.tab-container {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
  transform: translateY(-20px);
}

.opacity-enter-active,
.opacity-leave-active {
  transition: 0.5s;
}

.opacity-enter,
.opacity-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.edit-button {
  padding: 9px 5px;
  position: absolute;
  top: 0;
  left: -100px;
}

.edit-button--pj {
  transform: translateY(-50%);
}

.actions {
  margin-top: 0.5rem;
}

.pf-actions {
  margin-top: 1.5rem;
}
</style>
