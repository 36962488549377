var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    {
      ref: "formClientTab",
      staticStyle: { height: "100%" },
      attrs: { name: "formClientTab" },
    },
    [
      _c(
        "b-container",
        { staticClass: "tab-container" },
        [
          _c(
            "b-card-actions",
            { attrs: { "no-actions": "", "show-loading": _vm.fetching } },
            [
              !(_vm.isOrder || _vm.hasPalmBeerCustomer)
                ? _c(
                    "b-row",
                    { attrs: { "align-h": "end" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c("e-button", {
                            attrs: {
                              id: "pdv-tab-client-btn-clean",
                              variant: "danger",
                              text: "Limpar",
                              size: "sm",
                            },
                            on: { click: _vm.onCleanForm },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "5" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "pdv-tab-client-person-type",
                          value: _vm.stClientForm.personType,
                          name: "personType",
                          type: "label",
                          options: _vm.personTypesOptions(),
                          label: _vm.$t("Tipo"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "7" } },
                    [
                      !_vm.existClient
                        ? _c("FormulateInput", {
                            attrs: {
                              id: "pdv-tab-client-document",
                              mask: ["###.###.###-##", "##.###.###/####-##"],
                              name: "document",
                              type: "text-mask",
                              label: _vm.$t("CPF ou CNPJ"),
                              placeholder: _vm.$t("CPF ou CNPJ"),
                              validation: "optional|cpfCnpj",
                              disabled: _vm.isOrder || _vm.hasPalmBeerCustomer,
                            },
                            on: { input: _vm.onInputCustomerDocument },
                            model: {
                              value: _vm.stClientForm.document,
                              callback: function ($$v) {
                                _vm.$set(_vm.stClientForm, "document", $$v)
                              },
                              expression: "stClientForm.document",
                            },
                          })
                        : _c("FormulateInput", {
                            attrs: {
                              id: "pdv-tab-client-document",
                              name: "document",
                              type: "label",
                              label: _vm.$t("CPF ou CNPJ"),
                              filter: "cpfCnpj",
                            },
                            model: {
                              value: _vm.stClientForm.document,
                              callback: function ($$v) {
                                _vm.$set(_vm.stClientForm, "document", $$v)
                              },
                              expression: "stClientForm.document",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                !_vm.isAnonymous
                  ? _c(
                      "div",
                      [
                        _vm.isNatural
                          ? [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        md: !_vm.existClient
                                          ? "12"
                                          : _vm.isUpdating.name
                                          ? "8"
                                          : "4",
                                      },
                                    },
                                    [
                                      _c("FormulateInput", {
                                        ref: "name",
                                        attrs: {
                                          id: "pdv-tab-client-name",
                                          name: "name",
                                          type:
                                            _vm.existClient &&
                                            !_vm.isUpdating.name
                                              ? "label"
                                              : "text",
                                          label: _vm.$t("Nome"),
                                          placeholder: _vm.$t("Nome"),
                                        },
                                        model: {
                                          value: _vm.stClientForm.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.stClientForm,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "stClientForm.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.enableEdit("name")
                                    ? _c(
                                        "b-col",
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _vm.existClient &&
                                          !_vm.isUpdating.name &&
                                          _vm.stClientForm.id != 1
                                            ? _c("e-button", {
                                                staticClass: "edit-button",
                                                attrs: {
                                                  pill: "",
                                                  variant: "outline-secondary",
                                                  type: "button",
                                                  icon: "pencil",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.ableToUpdate(
                                                      "name"
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.isUpdating.name &&
                                          _vm.stClientForm.id
                                            ? _c(
                                                "b-col",
                                                {
                                                  staticClass:
                                                    "actions pf-actions",
                                                },
                                                [
                                                  _c("e-button", {
                                                    staticStyle: {
                                                      "margin-right": "6px",
                                                    },
                                                    attrs: {
                                                      title: "Confirmar",
                                                      size: "sm",
                                                      variant: "primary",
                                                      icon: "check-circle",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.updateCompanyNameOrName(
                                                          _vm.stClientForm.name,
                                                          "name"
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("e-button", {
                                                    attrs: {
                                                      title: "Cancelar",
                                                      size: "sm",
                                                      variant: "danger",
                                                      icon: "x-circle",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelUpdate(
                                                          "name"
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.isJuridical
                          ? [
                              _c(
                                "b-row",
                                { attrs: { "align-v": "center" } },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        md: !_vm.existClient
                                          ? "12"
                                          : _vm.isUpdating.companyName
                                          ? "8"
                                          : "4",
                                      },
                                    },
                                    [
                                      _c("FormulateInput", {
                                        ref: "companyName",
                                        attrs: {
                                          id: "pdv-tab-client-company-name",
                                          name: "corporateName",
                                          type:
                                            _vm.existClient &&
                                            !_vm.isUpdating.companyName
                                              ? "label"
                                              : "text",
                                          label: _vm.$t("Razão social"),
                                          placeholder: _vm.$t("Razão Social"),
                                        },
                                        model: {
                                          value: _vm.stClientForm.companyName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.stClientForm,
                                              "companyName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "stClientForm.companyName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.enableEdit("companyName")
                                    ? _c(
                                        "b-col",
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _vm.existClient &&
                                          !_vm.isUpdating.companyName &&
                                          _vm.stClientForm.id != 1
                                            ? _c("e-button", {
                                                staticClass:
                                                  "edit-button edit-button--pj",
                                                attrs: {
                                                  pill: "",
                                                  variant: "outline-secondary",
                                                  type: "button",
                                                  icon: "pencil",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.ableToUpdate(
                                                      "companyName"
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.isUpdating.companyName &&
                                          _vm.stClientForm.id
                                            ? _c(
                                                "div",
                                                { staticClass: "actions" },
                                                [
                                                  _c("e-button", {
                                                    staticStyle: {
                                                      "margin-right": "6px",
                                                    },
                                                    attrs: {
                                                      title: "Confirmar",
                                                      size: "sm",
                                                      variant: "primary",
                                                      icon: "check-circle",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.updateCompanyNameOrName(
                                                          _vm.stClientForm
                                                            .companyName,
                                                          "companyName"
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("e-button", {
                                                    attrs: {
                                                      title: "Cancelar",
                                                      size: "sm",
                                                      variant: "danger",
                                                      icon: "x-circle",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelUpdate(
                                                          "companyName"
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "pdv-tab-client-email",
                                    name: "email",
                                    type: _vm.existClient ? "label" : "text",
                                    validation: "optional|email",
                                    filter: _vm.existClient
                                      ? "emailMask"
                                      : null,
                                    label: _vm.$t("E-mail"),
                                    placeholder: _vm.$t("exemplo@email.com"),
                                  },
                                  model: {
                                    value: _vm.stClientForm.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.stClientForm, "email", $$v)
                                    },
                                    expression: "stClientForm.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                !_vm.existClient
                                  ? _c("FormulateInput", {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: "(##) ####-####",
                                          expression: "'(##) ####-####'",
                                        },
                                      ],
                                      attrs: {
                                        id: "pdv-tab-client-phone-landline",
                                        placeholder: _vm.$t("(99) 9999-9999"),
                                        name: "phone",
                                        type: "text",
                                        label: _vm.$t("Telefone"),
                                      },
                                      model: {
                                        value: _vm.stClientForm.landLine,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.stClientForm,
                                            "landLine",
                                            $$v
                                          )
                                        },
                                        expression: "stClientForm.landLine",
                                      },
                                    })
                                  : _c("FormulateInput", {
                                      attrs: {
                                        id: "pdv-tab-client-phone-landline",
                                        value: _vm.stClientForm.landLine,
                                        name: "phone",
                                        type: "label",
                                        label: _vm.$t("Telefone"),
                                        filter: "phone",
                                      },
                                    }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                !_vm.existClient
                                  ? _c("FormulateInput", {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: "(##) #####-####",
                                          expression: "'(##) #####-####'",
                                        },
                                      ],
                                      attrs: {
                                        id: "pdv-tab-client-phone-mobile",
                                        placeholder: _vm.$t("(99) 99999-9999"),
                                        name: "cellphone",
                                        type: _vm.existClient
                                          ? "label"
                                          : "text",
                                        filter: _vm.existClient
                                          ? "phone"
                                          : null,
                                        label: _vm.$t("Celular"),
                                      },
                                      model: {
                                        value: _vm.stClientForm.mobilePhone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.stClientForm,
                                            "mobilePhone",
                                            $$v
                                          )
                                        },
                                        expression: "stClientForm.mobilePhone",
                                      },
                                    })
                                  : _c("FormulateInput", {
                                      attrs: {
                                        id: "pdv-tab-client-phone-mobile",
                                        value: _vm.stClientForm.mobilePhone,
                                        name: "cellphone",
                                        type: "label",
                                        filter: "phone",
                                        label: _vm.$t("Celular"),
                                      },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c("transition", { attrs: { name: "fade" } }, [
            !_vm.isAnonymous
              ? _c(
                  "div",
                  [
                    _c(
                      "b-card-actions",
                      {
                        attrs: {
                          title: _vm.$t("Outros dados"),
                          "no-actions": "",
                          "show-loading": _vm.fetching,
                        },
                      },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "pdv-tab-client-observation",
                                    name: "observation",
                                    type: _vm.existClient
                                      ? "label"
                                      : "textarea",
                                    label: _vm.$t("Observação"),
                                    placeholder: _vm.$t("Observação"),
                                  },
                                  model: {
                                    value: _vm.stClientForm.observation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.stClientForm,
                                        "observation",
                                        $$v
                                      )
                                    },
                                    expression: "stClientForm.observation",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.isNatural
                          ? [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "pdv-tab-client-birthdate",
                                          name: "birthdate",
                                          type: _vm.existClient
                                            ? "label"
                                            : "datepicker",
                                          filter: _vm.existClient
                                            ? "dateMask"
                                            : null,
                                          label: _vm.$t("Data de nascimento"),
                                          disabled: _vm.existClient,
                                          max: _vm.today,
                                        },
                                        model: {
                                          value: _vm.stClientForm.birthdate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.stClientForm,
                                              "birthdate",
                                              $$v
                                            )
                                          },
                                          expression: "stClientForm.birthdate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.isJuridical
                          ? [
                              _c(
                                "b-row",
                                { attrs: { "align-v": "center" } },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        md: !_vm.existClient
                                          ? "12"
                                          : _vm.isUpdating.name
                                          ? "8"
                                          : "4",
                                      },
                                    },
                                    [
                                      _c("FormulateInput", {
                                        ref: "name",
                                        attrs: {
                                          id: "pdv-tab-client-name",
                                          name: "name",
                                          type:
                                            _vm.existClient &&
                                            !_vm.isUpdating.name
                                              ? "label"
                                              : "text",
                                          label: _vm.$t("Nome Fantasia"),
                                          placeholder: _vm.$t("Nome Fantasia"),
                                        },
                                        model: {
                                          value: _vm.stClientForm.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.stClientForm,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "stClientForm.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.enableEdit("name")
                                    ? _c(
                                        "b-col",
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _vm.existClient &&
                                          !_vm.isUpdating.name
                                            ? _c("e-button", {
                                                staticClass:
                                                  "edit-button edit-button--pj",
                                                attrs: {
                                                  pill: "",
                                                  variant: "outline-secondary",
                                                  type: "button",
                                                  icon: "pencil",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.ableToUpdate(
                                                      "name"
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.isUpdating.name &&
                                          _vm.stClientForm.id
                                            ? _c(
                                                "div",
                                                { staticClass: "actions" },
                                                [
                                                  _c("e-button", {
                                                    staticStyle: {
                                                      "margin-right": "6px",
                                                    },
                                                    attrs: {
                                                      title: "Confirmar",
                                                      size: "sm",
                                                      variant: "primary",
                                                      icon: "check-circle",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.updateCompanyNameOrName(
                                                          _vm.stClientForm.name,
                                                          "name"
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("e-button", {
                                                    attrs: {
                                                      title: "celar",
                                                      size: "sm",
                                                      variant: "danger",
                                                      icon: "x-circle",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelUpdate(
                                                          "name"
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "pdv-tab-client-state-registration-type",
                                          name: "stateRegistrationType",
                                          type: _vm.existClient
                                            ? "label"
                                            : "vue-select",
                                          label: _vm.$t("Indicador da IE"),
                                          placeholder: _vm.$t("Selecione"),
                                          options: _vm.indicatorIETypes(),
                                        },
                                        model: {
                                          value:
                                            _vm.stClientForm
                                              .stateRegistrationType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.stClientForm,
                                              "stateRegistrationType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "stClientForm.stateRegistrationType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "pdv-tab-client-state-registration",
                                          name: "stateRegistration",
                                          type: _vm.existClient
                                            ? "label"
                                            : "text",
                                          filter: _vm.existClient
                                            ? "rgIeMask"
                                            : null,
                                          label: _vm.$t("Inscrição estadual"),
                                          maxlength: "20",
                                          disabled: [
                                            _vm.indicatorIETypesEnum
                                              .EXEMPT_REGISTRATION,
                                            _vm.indicatorIETypesEnum
                                              .NO_TAXPAYER,
                                          ].includes(
                                            _vm.stClientForm
                                              .stateRegistrationType
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.stClientForm.stateRegistration,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.stClientForm,
                                              "stateRegistration",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "stClientForm.stateRegistration",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm.existClient
                              ? [
                                  _c(
                                    "b-card-actions",
                                    {
                                      attrs: {
                                        title: _vm.$t("Endereço"),
                                        "show-loading": _vm.fetching,
                                        "no-actions": "",
                                      },
                                    },
                                    [
                                      _c("e-address", {
                                        attrs: {
                                          required: false,
                                          "show-loading": true,
                                          "read-only": true,
                                        },
                                        model: {
                                          value: _vm.stClientForm.address,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.stClientForm,
                                              "address",
                                              $$v
                                            )
                                          },
                                          expression: "stClientForm.address",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c("e-address", {
                                    attrs: {
                                      required: false,
                                      "show-loading": _vm.fetching,
                                    },
                                    model: {
                                      value: _vm.stClientForm.address,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.stClientForm,
                                          "address",
                                          $$v
                                        )
                                      },
                                      expression: "stClientForm.address",
                                    },
                                  }),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }